<!-- <template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/>
  </div>
</template> -->
<template>
  <!-- <div class="home">
    <br>
    <img alt="CDCCE" src="../assets/bg.jpg">

  </div> -->

  <div class="container"></div>

</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>


<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld
  }
}
</script>
<style scoped lang="scss">
.container {
  height: 566px;
  width: 1000px;
  background-image: url('../assets/home_bg.png');
}
</style>
