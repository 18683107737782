<template>
  <div class="container">
    <label for="example-datepicker">請選擇西元生日</label>
    <b-form-datepicker id="example-datepicker" v-model="form.dateValue" class="mb-2"></b-form-datepicker>
    <b-button @click="getDateString" type="submit" variant="primary">計算</b-button>
    <p></p>
    <p>生日: {{ form.dateValue }}</p>
    <!-- <span v-model="year">年:{{year}}</span>
    <span v-model="month">月:{{month}}</span>
    <span v-model="day">日:{{day}}</span>

    <span v-model="totalOneOne">總和1:{{totalOneOne}}</span>/
    <span v-model="totalTwoTwo">總和2:{{totalTwoTwo}}</span>/
    <span v-model="totalThreeThree">總和3:{{totalThreeThree}}</span>
    <hr> -->
    <p v-model="innerColorOK">
      <!-- inner Color Number is:{{innerColorOK}} -->
    </span>
      <!-- <hr>
    <span v-model="totalY">Year總和:{{totalY}}</span>/
    <span v-model="totalM">Month總和:{{totalM}}</span>/
    <span v-model="totalD">Day總和:{{totalD}}</span>
    <hr> -->

    <span v-model="innerColorCode">
      <!-- {{innerColorCode}} -->
    </span>

    <p v-model = "innerDesc" ></p>
    <table border = "1" align="center" width="600">
      <tr>
        <td>潛能數字</td>
        <td>我的專屬色</td>
        <td>我的性格／潛能探索</td>
      </tr>
      <tr>
        <td v-model="innerColorOK">{{innerColorOK}}</td>
        <td v-model="innerColorCode" v-bind:style="{ backgroundColor: innerColorCode}">{{innerColorCode}}</td>
        <td v-model = "innerDesc" v-html="innerDesc">{{ innerDesc}}</td>
      </tr>
    </table>



  </div>
</template>

<script>
  export default {
    data() {
      return {
        year:"",
        month:"",
        day:"",
        total:"",
        totalY:"",
        totalM:"",
        totalD:"",
        totalOneOne:"",
        totalTwoTwo:"",
        totalThreeThree:"",
        innerColorOK:"",
        innerDesc: "",
        innerColorCode:"",

        icNum: "",
        icCol: "",
        icDes: "",

        form:{
          dateValue: '',
        },
        innerColorArray:[
          // "這1", "這2", "這3", "這4", "這5", "這6", "這7", "這8", "這9"
          { id:"#FF0000", desc:"性格：強烈自我，勇往直前，很有活力、行動力、表現慾望強烈、喜歡被讚美,讚美可以讓幼兒因受肯定而有更傑出的表現。陽剛氣重、好動、體力好、有自信心。<hr>潛能探索：身體的力量～著重心肺訓練，以體力設計發展腦力，需要無阻礙的流動與表達！" },
          { id:"#FF5809", desc:"性格：平衡性，有服務精神，喜歡團體，是很好的康樂股長個性包容，溫暖善解人意，多才多藝，個性較容易產生矛盾、情緒化。喜歡與人相處、合作。<hr>潛能探索：可用音樂來挖掘藝術潛能。音樂很適合橙色基因的幼兒，透過音律與旋律在挖掘潛質過程，同時能安撫與慰藉幼兒的情緒。這特質的幼兒情緒越穩定，越能讓他的優秀能力茁壯與展現！" },
          { id:"#F9F900", desc:"性格：聰明伶俐，活力充沛。從小就展現古靈精怪的思考特質，能將情感與理念用最直接、誠實的方式表達。單純為特點，很有創造力、好奇心重、直覺性強。需要幫助培養自信心，是最佳演說家！<hr>潛能探索：原創性，創造力的訓練。訓練其腦力與智力發展，很喜歡問問題，一百萬個為什麼，有群眾魅力、有發明潛力、可試著挖掘語言與思考。" },
          { id:"#64A600", desc:"性格:實事求是的穩健人格特質。容易裹足不前，需要站穩這一步，才會放心走下一步。求穩固、怕變動、擁有正面積極的態度，是個情緒較為平衡的孩子。需要說清楚、講道理，較缺乏創意與想像力。<hr>潛能探索：數字。訓練數字觀念！綠色基因的潛質不管如何，從小訓練他的數字與理財觀念～絕對有意想不到的發展！" },
          { id:"#46A3FF", desc:"性格:天空的顏色，最冷的色調。代表左腦，思考、語言能力。喜歡自由不受拘束，排斥規則，較為自我。<hr>潛能探索:擁有優異的語言學習力，主導思考能力，左腦發達。藍色基因發達的幼兒，有高比例是資優生或是擁有高專注學習力。但也容易沉溺在自己有興趣的事情。" },
          { id:"#2828FF", desc:"性格:具有所謂宇宙基因。靛藍小孩，直覺性強、很有靈性、悲天憫人。是個很好的傾聽者、喜歡助人，有時得失心較重。<hr>潛能探索:探索能力。對於宇宙與大自然有著強烈的好奇心與理解力。可試著挖掘此基因幼兒的右腦能力。" },
          { id:"#6F00D2", desc:"性格:智慧思考的象徵顏色。有偵探的天賦，也有著豐富的內心世界。情緒與感受力豐富，很有藝術天分！喜歡探討挖掘真相與答案，思考力非常發達，對神秘文化也非常有天分，有時個性比較不合群、封閉，因此有一定比例是自閉特質。<hr>潛能探索:思考。訓練思考能力，很敏感細膩的特質，藝術潛能發展。第六感的能力頗發達，音樂天分也高。" },
          { id:"#FF9224", desc:"性格:夢想家的人格基因。最有心想事成的人格潛力，成敗都在心想。黃金的價值在於純度，心念也是！創業家的因子，心性決定未來。如果這樣的孩子跟你說將來想上月球，千萬要當真去培養。<hr>潛能探索:很愛作夢的潛能。也很有商業頭腦基因～俗稱的生意小孩。從無到有的遊戲引導，最能挖掘這基因的幼兒潛能，保護他的夢想力，畢竟這是屬於可以心想事成的潛能。" },
          { id:"#FDFFFF", desc:"性格:主觀性強，具有明朗清楚的個性。很挑剔的完美主義者、自我要求高。有優越感與自戀傾向，看似很願意聽話很願意接納意見，但是到最後還是會以自己的觀點為主。性情上很純潔單純，心地善良樂善好施，有些有逃避現實沉浸在自我世界裡。<hr>潛能探索:理解力的訓練，發展溝通的訓練，精神性最強，很有奉獻的基因，訓練原則、思辨潛能。" },
        ],
        // fields: ['潛能數字', '我的專屬色', '我的性格／潛能探索'],

        fields: [
          // A column that needs custom formatting
          { key: 'icNum', label: '潛能數字' },
          // A regular column
          { key: 'icCol', label: '我的專屬色' },
          // A regular column
          { key: 'icDes', label: '我的性格／潛能探索' },
          // A virtual column made up from two fields
          // { key: 'nameage', label: 'First name and age' }
        ],
        items: [

          // { icNum: this.itemNum, icCol: 'Female', icDes: 36 },

          // { name: { first: 'Rubin', last: 'Kincade' }, sex: 'Male', age: 73 },
          // { name: { first: 'Shirley', last: 'Partridge' }, sex: 'Female', age: 62 }
        ],


      }
    },
    methods: {
      // ...mapActions(['fetchInnerColorItem']),
      getDateString: function() {
        this.year = this.form.dateValue.slice(0,4)
        this.month = this.form.dateValue.slice(5,7)
        this.day = this.form.dateValue.slice(8,10)
        var y = parseInt(this.year)
        var m = parseInt(this.month)
        var d = parseInt(this.day)
        //將年月日合併成一個大字串後轉成整數
        var allString = this.year+this.month+this.day
        var allInt = parseInt(allString)
        // console.log(allInt)

        var totalOne = 0
        var totalTwo = 0
        var totalThree = 0
        var innerColor = 0

        var totalYY = 0
        var totalMM = 0
        var totalDD = 0

        //計算 inner ColorOK

        if(allInt){
          while(allInt/10 != 0){
            totalOne += allInt%10
            allInt = parseInt(allInt/10)
          }
        }

        if(totalOne < 10){
          innerColor = totalOne
        } else{
          while(totalOne/10 != 0){
            totalTwo += totalOne%10
            totalOne = parseInt(totalOne/10)
          }
          if(totalTwo < 10){
            innerColor = totalTwo
          } else{
            while(totalTwo/10 != 0){
              totalThree += totalTwo%10
              totalTwo = parseInt(totalTwo/10)
            }
            innerColor = totalThree
          }
        }
        //================================計算 inner ColorOK articleChanged
        // this.icNum = innerColor
        this.innerDesc = this.innerColorArray[innerColor-1].desc
        this.innerColorCode = this.innerColorArray[innerColor-1].id
        //設定結果給 innerColorItems
        // innerColorItems = [{itemNum:'innerColor'} , {itemColor:this.innerColorCode}, {item.description: this.innerDesc}]
        // this.items.push({this.icNum: innerColor, this.icCol: this.innerColorCode, this.icDes: fthis.innerDesc})
        // console.log(this.items)
        // addInnerColorItem: function(){
            // this.innerColorItems.push({this.icNum:"1", this.icCol:"#ff0000", this.icDes:"I am gigo"})
        // }


        while(y/10 != 0){
          totalYY += y%10
          y = parseInt(y/10)
        }

        while(m/10 != 0){
          totalMM += m%10
          m= parseInt(m/10)
        }

        while(d/10 != 0){
          totalDD += d%10
          d = parseInt(d/10)
        }
        //totalYY += y
        this.totalOneOne = parseInt(totalOne)
        this.totalTwoTwo = parseInt(totalTwo)
        this.totalThreeThree = parseInt(totalThree)
        this.innerColorOK = parseInt(innerColor)

        this.totalY = parseInt(totalYY)
        this.totalM = parseInt(totalMM)
        this.totalD = parseInt(totalDD)
        //this.total = y + m + d

      },
      // computed: {
      //   ...mapState(['innerColorItems']),
      // },
      rowClass(item, type) {
        if (!item || type !== 'row') return
        if (item.status === 'awesome') return 'table-success'
      },
    },
  }
</script>
<style scoped lang="scss">
.container {
  height: 566px;
  width: 800px;
  background-image: url('../assets/bg.jpg');
}
</style>
